<template>
	<v-container fluid class="d-flex flex-column align-center">
		<v-row justify="center" class="flex-grow-1 w-100" style="margin: 0px; padding-top: 20px;">
			<v-col cols="12" class="d-flex flex-column align-center" style="padding: 2px;">
				<div v-if="firstLoad">
					<PirateLoadingSpinner />
					<div v-if="errorMessage" class="error-container">
						<v-icon color="red">error</v-icon>
						<span class="error-text">{{ errorMessage }}</span>
					</div>
				</div>
				<div v-if="!firstLoad" class="content-container">
					<!-- Conditionally render the search bar based on the showSearch prop -->
					<v-text-field v-if="showSearch" v-model="searchQuery" label="Search" class="mb-4" outlined clearable
						@input="onSearchInput" @click:clear="fetchData(topUrl, true)"
						@keydown.enter="onEnterKey"></v-text-field>
					<div class="table-container">
						<v-table v-if="!errorMessage && displayedItems.length > 0">
							<thead>
								<tr>
									<th class="default-padding icon-header"></th>
									<th class="table-header default-padding">Name</th>
									<th class="table-header default-padding seeder-column">SE</th>
									<th class="table-header default-padding leecher-column">LE</th>
									<th class="table-header default-padding date-column">Date</th>
									<th class="table-header default-padding size-column">Size</th>
									<th class="table-header default-padding uploader-column">Uploader</th>
									<th class="table-header default-padding">Action</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(item, index) in displayedItems" :key="item.id">
									<ResultComponentRow :item="item" :searchUrl="searchUrl" :detailsUrl="detailsUrl"
										:magnetUrl="magnetUrl" :isOdd="index % 2 !== 0" />
								</template>
							</tbody>
						</v-table>
						<PirateLoadingSpinner v-if="isLoading && displaySpinner" />
						<div v-if="errorMessage" class="error-container">
							<v-icon color="red">error</v-icon>
							<span class="error-text">{{ errorMessage }}</span>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ResultComponentRow from "./ResultComponentRow.vue";
import backendService from "../services/backendService";
import PirateLoadingSpinner from './generic/PirateLoadingSpinner.vue';
import { hasBadQuality } from '../utils/qualityChecker';

export default {
	name: "ResultComponent",
	components: {
		ResultComponentRow,
		PirateLoadingSpinner,
	},
	props: {
		topUrl: {
			type: String,
			required: true,
		},
		searchUrl: {
			type: String,
			required: true,
		},
		detailsUrl: {
			type: String,
			required: true,
		},
		magnetUrl: {
			type: String,
			required: true
		},
		showSearch: { // Existing prop
			type: Boolean,
			default: true,
		},
		limit: { // Existing prop
			type: Number,
			default: null,
		},
		showTopNew: { // New prop to control the display of new torrents
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			searchQuery: "",
			items: [],
			isLoading: false,
			firstLoad: true,
			displaySpinner: false,
			errorMessage: "",
			expandedItemId: null,
			searchTimeout: null,
			page: 1,
			allDataLoaded: false,
		};
	},
	computed: {
		displayedItems() { // Computed property to handle item limiting and filtering
			let filteredItems = this.items;
			if (this.showTopNew) {
				filteredItems = filteredItems.filter(item => this.isWithinLastThreeDays(item.date) && !hasBadQuality(item.name));
				const uniqueNames = new Set();
				filteredItems = filteredItems.filter(item => {
					if (item.cleanedName) {
						if (!uniqueNames.has(item.cleanedName)) {
							uniqueNames.add(item.cleanedName);
							return true;
						}
						return false;
					}
					return true;
				});
			}
			return this.limit ? filteredItems.slice(0, this.limit) : filteredItems;
		}
	},
	methods: {
		async fetchData(url, reset = false) {
			if (this.allDataLoaded && !reset) return;

			if (url == "") {
				this.firstLoad = false;
				return;
			}

			this.errorMessage = "";

			const calculateItemWeight = (item) => {
				const seedsWeight = 1.0;
				const leechesWeight = 2.0;
				return item.seeds * seedsWeight + item.leeches * leechesWeight;
			};

			try {
				this.isLoading = true;
				this.showSpinner();
				const data = await backendService.get(url);
				if (reset) {
					this.items = data.map((item, index) => ({ ...item, id: index })).sort((a, b) => calculateItemWeight(b) - calculateItemWeight(a));
					this.page = 1;
					this.allDataLoaded = false;
				} else {
					const sortedNewData = data.map((item, index) => ({ ...item, id: this.items.length + index })).sort((a, b) => calculateItemWeight(b) - calculateItemWeight(a));
					this.items = [...this.items, ...sortedNewData];
					if (data.length === 0) {
						this.allDataLoaded = true;
					}
				}
				this.isLoading = false;
				this.displaySpinner = false;
			} catch (error) {
				this.errorMessage = error.message;
				this.isLoading = false;
				this.displaySpinner = false;
			}
			finally {
				this.firstLoad = false;
			}
		},
		onSearchInput() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}

			this.searchTimeout = setTimeout(() => {
				if (this.searchQuery) {
					this.fetchData(`${this.searchUrl}?name=${this.searchQuery}`, true);
				} else {
					this.fetchData(this.topUrl, true);
				}
			}, 500);
		},
		showSpinner() {
			setTimeout(() => {
				if (this.isLoading) {
					this.displaySpinner = true;
				}
			}, 300);
		},
		onEnterKey() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}
			// Create a hidden input field, focus and blur it to close the keyboard
			const hiddenInput = document.createElement('input');
			hiddenInput.setAttribute('type', 'text');
			hiddenInput.style.position = 'absolute';
			hiddenInput.style.left = '-9999px';
			hiddenInput.style.top = '-9999px';
			hiddenInput.style.opacity = 0;
			hiddenInput.style.height = 0;
			hiddenInput.style.fontSize = '16px'; // to avoid zoom on iOS
			document.body.appendChild(hiddenInput);
			hiddenInput.focus();
			hiddenInput.blur();
			document.body.removeChild(hiddenInput);
			this.fetchData(`${this.searchUrl}?name=${this.searchQuery}`, true);
		},
		onScroll() {
			if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && !this.isLoading) {
				// Check if we are searching
				if (this.searchQuery) {
					this.page++;
					this.fetchData(`${this.searchUrl}?name=${this.searchQuery}&page=${this.page}`);
				}
			}
		},
		isWithinLastThreeDays(dateString) { // Reuse the existing method
			const [day, month] = dateString.split('/').map(Number);
			const today = new Date();
			const currentYear = today.getFullYear();
			const inputDate = new Date(currentYear, month - 1, day);
			const timeDifference = today - inputDate;
			const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
			return dayDifference >= 0 && dayDifference <= 3;
		},
	},
	mounted() {
		this.fetchData(this.topUrl, true);
		window.addEventListener('scroll', this.onScroll);
	},
	beforeUnmount() {
		window.removeEventListener('scroll', this.onScroll);
	}
};
</script>


<style scoped>
.icon {
	padding: 0px 8px 0px 8px !important;
}

.default-padding {
	padding: 0px 48px 0px 0px !important;
}

.no-padding {
	padding: 0px !important;
}

thead {
	background-color: #2e2e2e;
}

.table-header {
	font-weight: bold;
	background-color: #2e2e2e;
}

.v-container {
	padding: 0px !important;
}

.content-container {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
}

.table-container {
	width: 100%;
}

.new-icon {
	color: red;
	font-weight: bold;
	margin-left: 8px;
}

.error-container {
	display: flex;
	align-items: center;
	color: red;
	margin-top: 10px;
}

.error-text {
	color: red;
	margin-left: 8px;
	font-weight: bold;
}

@media (max-width: 1300px) {
	.uploader-column {
		display: none;
	}

	.default-padding {
		padding: 0px 16px 0px 0px !important;
	}
}

@media (max-width: 900px) {
	.date-column {
		display: none;
	}

	.default-padding {
		padding: 0px 8px 0px 0px !important;
	}
}

@media (max-width: 600px) {
	.icon-header {
		display: none;
	}

	.leecher-column {
		display: none;
	}

	.seeder-column {
		display: none;
	}
}
</style>