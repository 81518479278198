const badQualityTags = [
	"CAM", "TSRIP", "HDTS", "HD-CAM", "WEBCAM", "HDCAM", "HDCAM-RIP", "TS",
	"TELESYNC", "PDVD", "SD", "480P", "360P", "720P", "LD", "LOWDEF",
	"SCREENER", "SCR", "R5", "TC", "TELECINE", "DVDSCR", "WORKPRINT", "HINDI"
];

function tokenizeTitle(title) {
	return title.split(/[.\-_\s]/);
}

function containsBadQualityTag(tokens) {
	return tokens.some(token => badQualityTags.includes(token.toUpperCase()));
}

export function hasBadQuality(title) {
	const tokens = tokenizeTitle(title);
	return containsBadQualityTag(tokens);
}