--- src/components/BaseResultComponent.vue ---
<template>
	<v-container fluid class="d-flex flex-column align-center">
		<v-row justify="center" class="flex-grow-1 w-100" style="margin: 0px; padding-top: 20px;">
			<v-col cols="12" class="d-flex flex-column align-center" style="padding: 2px;">
				<div class="content-container">
					<v-text-field v-if="showSearch" v-model="searchQuery" label="Search" class="mb-4" outlined clearable
						@input="onSearchInput" @click:clear="fetchData(initialFetchUrl, true)"
						@keydown.enter="onEnterKey"></v-text-field>
					<div class="table-container">
						<v-table v-if="!errorMessage && displayedItems.length > 0">
							<thead>
								<tr>
									<slot name="table-headers"></slot>
								</tr>
							</thead>
							<tbody>
								<template v-for="(item, index) in displayedItems" :key="getItemKey(item)">
									<slot name="table-row" :item="item" :index="index"></slot>
								</template>
							</tbody>
						</v-table>
						<PirateLoadingSpinner v-if="isLoading && displaySpinner" />
						<div v-if="errorMessage" class="error-container">
							<v-icon color="red">error</v-icon>
							<span class="error-text">{{ errorMessage }}</span>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import localBackendService from '@/services/localBackendService';
import PirateLoadingSpinner from './generic/PirateLoadingSpinner.vue';
import backendService from "@/services/backendService";
import { hasBadQuality } from '../utils/qualityChecker';

export default {
	name: "BaseResultComponent",
	components: {
		PirateLoadingSpinner,
	},
	props: {
		topUrl: {
			type: String,
			required: false,
			default: '',
		},
		searchUrl: {
			type: String,
			required: true,
		},
		detailsUrl: {
			type: String,
			required: true,
		},
		magnetUrl: {
			type: String,
			required: false,
			default: '',
		},
		showSearch: {
			type: Boolean,
			default: true,
		},
		limit: {
			type: Number,
			default: null,
		},
		showTopNew: {
			type: Boolean,
			default: false,
		},
		initialFetchUrl: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			searchQuery: "",
			items: [],
			isLoading: false,
			displaySpinner: false,
			isComponentActive: true,
			errorMessage: "",
			searchTimeout: null,
			page: 1,
			allDataLoaded: false,
			focusTimeout: null, // Added to handle focus event debouncing
			refreshInterval: null, // Added to store the interval ID
		};
	},
	computed: {
		displayedItems() {
			let filteredItems = this.items;
			if (this.showTopNew) {
				filteredItems = filteredItems.filter(item => this.isWithinLastThreeDays(item.date) && !hasBadQuality(item.name));
				const uniqueNames = new Set();
				filteredItems = filteredItems.filter(item => {
					if (item.cleanedName) {
						let checkCleanedName = item.cleanedName.toLowerCase();
						if (item.season) {
							checkCleanedName += item.season;
						}
						if (item.episode) {
							checkCleanedName += item.episode;
						}
						if (!uniqueNames.has(checkCleanedName)) {
							uniqueNames.add(checkCleanedName);
							return true;
						}
						return false;
					}
					return true;
				});
			}
			return this.limit ? filteredItems.slice(0, this.limit) : filteredItems;
		}
	},
	methods: {
		getItemKey(item) {
			// Define a unique key for each item
			return item.id || item.name || item.title || item.key;
		},
		async fetchData(url, reset = false) {
			if (this.allDataLoaded && !reset) return;

			if (!this.isComponentActive) {
				return;
			}

			if (url === "") {
				return;
			}
			this.errorMessage = "";

			try {
				let data = null;
				if (this.initialFetchUrl === url) {
					data = localBackendService.localGet(url);
					if (data) {
						this.updateItems(data, reset);
					}
					else {
						this.isLoading = true;
						this.showSpinner();
					}

					data = await localBackendService.remoteGet(url);
				}

				if (data == null) {
					this.isLoading = true;
					this.showSpinner();
					data = await backendService.get(url);
				}

				this.updateItems(data, reset);

				this.isLoading = false;
				this.displaySpinner = false;
			} catch (error) {
				this.errorMessage = error.message;
				this.isLoading = false;
				this.displaySpinner = false;
			}
		},
		updateItems(newData, reset) {
			if (reset) {
				this.items = newData
					.map((item, index) => ({ ...item, id: index }))
					.sort(this.sortItems);
				this.page = 1;
				this.allDataLoaded = false;
			} else {
				const sortedNewData = newData
					.map((item, index) => ({ ...item, id: this.items.length + index }))
					.sort(this.sortItems);
				this.items = [...this.items, ...sortedNewData];
				if (newData.length === 0) {
					this.allDataLoaded = true;
				}
			}

			// After updating items, check if more data needs to be loaded
			this.$nextTick(() => {
				this.checkAndLoadMore();
			});
		},
		sortItems(a, b) {
			// Default sorting: descending based on 'seeds' or similar numeric field
			const aWeight = this.calculateItemWeight(a);
			const bWeight = this.calculateItemWeight(b);
			return bWeight - aWeight;
		},
		calculateItemWeight(item) {
			// Default weight calculation: seeds * 1 + leeches * 2
			const seedsWeight = 1.0;
			const leechesWeight = 2.0;
			return (parseInt(item.seeds) || 0) * seedsWeight + (parseInt(item.leeches) || 0) * leechesWeight;
		},
		onSearchInput() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}

			this.searchTimeout = setTimeout(() => {
				if (this.searchQuery) {
					this.fetchData(`${this.searchUrl}?name=${encodeURIComponent(this.searchQuery)}`, true);
				} else {
					this.fetchData(this.initialFetchUrl, true);
				}
			}, 500);
		},
		showSpinner() {
			setTimeout(() => {
				if (this.isLoading) {
					this.displaySpinner = true;
				}
			}, 300);
		},
		onEnterKey() {
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}
			// Create a hidden input field, focus and blur it to close the keyboard
			const hiddenInput = document.createElement('input');
			hiddenInput.setAttribute('type', 'text');
			hiddenInput.style.position = 'absolute';
			hiddenInput.style.left = '-9999px';
			hiddenInput.style.top = '-9999px';
			hiddenInput.style.opacity = 0;
			hiddenInput.style.height = 0;
			hiddenInput.style.fontSize = '16px'; // to avoid zoom on iOS
			document.body.appendChild(hiddenInput);
			hiddenInput.focus();
			hiddenInput.blur();
			document.body.removeChild(hiddenInput);
			this.fetchData(this.searchQuery ? `${this.searchUrl}?name=${encodeURIComponent(this.searchQuery)}` : this.initialFetchUrl, true);
		},
		onScroll() {
			this.checkAndLoadMore();
		},
		isWithinLastThreeDays(dateString) {
			const [day, month] = dateString.split('/').map(Number);
			const today = new Date();
			const currentYear = today.getFullYear();
			const inputDate = new Date(currentYear, month - 1, day);
			const timeDifference = today - inputDate;
			const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
			return dayDifference >= 0 && dayDifference <= 3;
		},
		handleVisibilityChange() {
			if (document.visibilityState === 'visible') {
				if (!this.searchQuery) {
					this.fetchData(this.initialFetchUrl, true);
				}
			}
		},
		handleFocus() {
			if (!this.searchQuery) {
				this.fetchData(this.initialFetchUrl, true);
			}
		},
		checkAndLoadMore() {
			if (!this.searchQuery)
				return;
			if (this.isLoading || this.allDataLoaded)
				return;

			const windowHeight = window.innerHeight;
			const documentHeight = document.body.offsetHeight;
			const scrollPosition = window.innerHeight + window.scrollY;
			const targetScroll = documentHeight - windowHeight * 0.5;

			if (scrollPosition >= targetScroll) {
				// Determine the next page URL
				let nextPageUrl = '';
				if (this.searchQuery) {
					nextPageUrl = `${this.searchUrl}?name=${encodeURIComponent(this.searchQuery)}&page=${this.page + 1}`;
				} else if (this.topUrl) {
					nextPageUrl = `${this.initialFetchUrl}?page=${this.page + 1}`;
				} else {
					// If no search and no topUrl, use initialFetchUrl with next page
					nextPageUrl = `${this.initialFetchUrl}?page=${this.page + 1}`;
				}

				// Increment the page number before fetching to prevent duplicate fetches
				this.page += 1;
				this.fetchData(nextPageUrl);
			}
		},
	},
	activated() {
		this.isComponentActive = true;
		// Silently fetch data when the component is re-activated
		this.fetchData(
			this.searchQuery
				? `${this.searchUrl}?name=${encodeURIComponent(this.searchQuery)}`
				: this.initialFetchUrl,
			true
		);
	},
	deactivated() {
		this.isComponentActive = false;
	},
	mounted() {
		// Fetch initial data
		this.fetchData(this.initialFetchUrl, true);

		// Add scroll event listener
		window.addEventListener('scroll', this.onScroll);

		// Add visibility change event listener
		document.addEventListener('visibilitychange', this.handleVisibilityChange);

		// Add focus event listener
		window.addEventListener('focus', this.handleFocus);
	},
	beforeUnmount() {
		// Remove scroll event listener
		window.removeEventListener('scroll', this.onScroll);

		// Remove visibility change event listener
		document.removeEventListener('visibilitychange', this.handleVisibilityChange);

		// Remove focus event listener
		window.removeEventListener('focus', this.handleFocus);
	},
};
</script>

<style scoped>
.icon {
	padding: 0px 8px 0px 8px !important;
}

.default-padding {
	padding: 0px 48px 0px 0px !important;
}

.no-padding {
	padding: 0px !important;
}

thead {
	background-color: #2e2e2e;
}

.table-header {
	font-weight: bold;
	background-color: #2e2e2e;
}

.v-container {
	padding: 0px !important;
}

.content-container {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;
}

.table-container {
	width: 100%;
}

.error-container {
	display: flex;
	align-items: center;
	color: red;
	margin-top: 10px;
}

.error-text {
	color: red;
	margin-left: 8px;
	font-weight: bold;
}

@media (max-width: 1300px) {
	.default-padding {
		padding: 0px 16px 0px 0px !important;
	}
}

@media (max-width: 900px) {
	.default-padding {
		padding: 0px 8px 0px 0px !important;
	}
}
</style>
